import {h, Component} from 'preact';

function isValidMember(member) {
    return (member.attending && member.name) || !member.attending;
}

function getAttendingMembers(members) {
    return members.filter(member => member.name && member.attending);
}

export default class extends Component {
    constructor(props) {
        super(props);
        const {rsvp} = props;
        const members = rsvp.members;
        if (members.length < rsvp.max_members) {
            const remaining_members = rsvp.max_members - members.length;
            for (let i = 0; i < remaining_members; i += 1) {
                members.push({
                    name: '',
                    attending: false,
                });
            }
        }
        this.state = {
            members: rsvp.members,
        };
    }

    saveRsvp(ev) {
        ev.preventDefault();
        const rsvp = {...this.props.rsvp};
        rsvp.members = this.state.members.slice();
        this.props.onSave(rsvp);
    }

    updateMember(member, newMember) {
        const memberIndex = this.state.members.indexOf(member);
        const newMembers = this.state.members.slice();
        newMembers.splice(memberIndex, 1, newMember);
        if (!!member.name !== !!newMember.name) {
            newMember.attending = !!newMember.name;
        }
        this.setState({members: newMembers});
    }

    validForm() {
        return this.state.members.every(isValidMember) && getAttendingMembers(this.state.members).length <= this.props.rsvp.max_members;
    }

    render(props, state) {
        console.log(props.rsvp);
        return (
            <form onSubmit={this.saveRsvp.bind(this)}>
                <h3>Please Confirm Who Will Be Attending</h3>
                {state.members.map(member => (
                    <RsvpMember
                        member={member}
                        onChange={newMember => this.updateMember(member, newMember)}
                    />
                ))}
                <button type="submit" disabled={!this.validForm()}>RSVP</button>
            </form>
        )
    }
}

class RsvpMember extends Component {
    update(prop, value) {
        const newMember = {...this.props.member};
        newMember[prop] = value;
        this.props.onChange(newMember);
    }

    render({member: {name, attending}}) {
        return (
            <div className="form-group with-checkbox">
                <input
                    type="text"
                    value={name}
                    onInput={(ev) => this.update('name', ev.target.value)}
                    placeholder="Additional Guest"
                />
                <label className="checkbox">
                    <input
                        type="checkbox"
                        checked={attending}
                        onInput={(ev) => this.update('attending', ev.target.checked)}
                    />
                    <i className="fa fa-check fa-2x" />
                    <i className="fa fa-times fa-2x" />
                </label>
            </div>
        );
    }
}
