import {h, render, Component} from 'preact';
import linkState from 'linkstate';
import firebase from 'firebase/app';

import RsvpForm from './form';

const db = firebase.firestore();

class RSVP extends Component {
    constructor(props) {
        super(props);
        const rsvpId = window.location.pathname.split('/')[2];
        this.state = {
            loading: !!rsvpId,
            rsvpId,
        };
    }

    componentDidMount() {
        if (this.state.rsvpId) {
            this.fetchRsvp();
        }
    }

    changeRsvp(ev) {
        ev.preventDefault();
        history.pushState({}, '', `/rsvp/${this.state.rsvpId}`);
        this.setState({loading: true});
        this.fetchRsvp();
    }

    async fetchRsvp() {
        try {
            const rsvpDoc = await db.collection('rsvps').doc(this.state.rsvpId);
            const rsvp = await rsvpDoc.get();
            const rsvpData = rsvp.data() || null;
            this.setState({rsvp: rsvpData, rsvpDoc, loading: false, error: !rsvpData});
        } catch (err) {
            this.setState({loading: false, error: true, rsvp: null});
        }
    }

    async saveRsvp(rsvp) {
        const {rsvpDoc} = this.state;
        if (!rsvp.completed) {
            rsvp.completed = firebase.firestore.Timestamp.now();
        }
        try {
            await rsvpDoc.set(rsvp);
            this.setState({saved: true});
        } catch (err) {
            // pass
        }
    }

    navigate(page) {
        return function (ev) {
            ev.preventDefault();
            window.navigate(page);
        };
    }

    render(_1, {loading, rsvp, error, rsvpId, saved}, _2) {
        if (loading) {
            return <p>Loading...</p>;
        }
        if (rsvp && !saved) {
            return <RsvpForm rsvp={rsvp} onSave={this.saveRsvp.bind(this)} />;
        } else if (saved) {
            return (
                <div>
                    <h2 style={{textAlign: 'center'}}>Thank you for your RSVP!</h2>
                    <div className="rsvp-complete-buttons">
                        <a
                            className="button"
                            href="/registry"
                            onClick={this.navigate('registry')}
                        >
                            Our Registry
                        </a>
                        <a
                            className="button"
                            href="/hotel"
                            onClick={this.navigate('hotel')}
                        >
                            Hotel Info
                        </a>
                    </div>
                </div>
            );
        }
        return (
            <form onSubmit={this.changeRsvp.bind(this)}>
                {error && <h2>Invalid RSVP</h2>}
                <label for="rsvp">
                    RSVP Number:
                    <input type="text" name="rsvp" value={rsvpId} onInput={linkState(this, 'rsvpId')} />
                </label>
                <button type="submit">Retrieve</button>
            </form>
        )
    }
}

const rsvpEl = document.getElementById('rsvp');
if (rsvpEl) {
    render( <RSVP/>, rsvpEl );
}
