import {h, render, Component} from 'preact';
import firebase from 'firebase/app';

const db = firebase.firestore();

class RsvpStats extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.fetchRsvpStats();
    }

    async fetchRsvpStats() {
        try {
            const incompleteRsvps = await db.collection('rsvps').where('completed', '==', null).get();
            const completeRsvps = await db.collection('rsvps').where('completed', '<', new Date()).get();
            const attending = [];
            const notAttending = [];
            const haventRsvpd = [];
            completeRsvps.forEach((doc) => {
                const completed = doc.data();
                for (const member of completed.members) {
                    if (member.name !== '') {
                        if( member.attending ) {
                            attending.push( member.name );
                        } else {
                            notAttending.push( member.name );
                        }
                    }
                }
            });
            incompleteRsvps.forEach((doc) => {
                const incomplete = doc.data();
                for (const member of incomplete.members) {
                    if (member.name !== '') {
                        haventRsvpd.push(member.name);
                    }
                }
            });
            this.setState({attending, notAttending, haventRsvpd});
        } catch (err) {
            console.error(err);
            this.setState({attending: null, notAttending: null, haventRsvpd: null});
        }
    }

    render(_1, {attending, notAttending, haventRsvpd}, _2) {
        console.log(attending, notAttending, haventRsvpd);
        if (!attending || !notAttending || !haventRsvpd) {
            return <p>Loading...</p>;
        }
        return (
            <table>
                <tr>
                    <th>Attending ({attending.length})</th>
                    <th>Not Attending ({notAttending.length})</th>
                    <th>Awaiting Reply ({haventRsvpd.length})</th>
                </tr>
                <tr>
                    <td>
                        <ul>
                            {attending.map(name => <li key={name}>{name}</li>)}
                        </ul>
                    </td>
                    <td>
                        <ul>
                            {notAttending.map(name => <li key={name}>{name}</li>)}
                        </ul>
                    </td>
                    <td>
                        <ul>
                            {haventRsvpd.map(name => <li key={name}>{name}</li>)}
                        </ul>
                    </td>
                </tr>
            </table>
        )
    }
}

const statsEl = document.getElementById('stats');
if (statsEl) {
    render(<RsvpStats/>, statsEl);
}
