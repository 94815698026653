import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';

firebase.initializeApp({
    apiKey: "AIzaSyAV726wVslMeWCXR3SA10u5kGIn191YYIo",
    authDomain: "the-goulden-ratio.firebaseapp.com",
    databaseURL: "https://the-goulden-ratio.firebaseio.com",
    projectId: "the-goulden-ratio",
    storageBucket: "the-goulden-ratio.appspot.com",
    messagingSenderId: "23509222321"
});

const db = firebase.firestore();
db.settings({
    timestampsInSnapshots: true,
});
